
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: #61dafb;
  font-size: calc(10px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.awal {
  height: 100vh;
  background: url("../images/bg/back2.jpg");
}
.boy {
  height : 100vh;
  background-color: maroon;
  border-top-left-radius: 80%;
}
.images {
  height: 250px;
}
.MatPelImage{
  height: auto;
  background-color: black;
  margin: 5px;
  padding: 5px;
}
.Navbar{
  height: 10vh;
  background: url("../images/bg/back1.jpg");

}
#intro1{
  margin-top: 10vh;
  font-size: 50px;
  font-style: oblique;
}
.logoIntro{
  height: 600px;
  position: relative;
}
.desc{
  font-size: large;
}

.container-slider {
  max-width: 1000px;
  height: 500px;
  margin: 0px auto 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 700px){
  .container-slider {
    margin: 100px 10px 0;
  }
}
.slide {
  width: 1000px;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 1000px;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}


.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}
.slider{
  margin-bottom: 0px;
  background-color: maroon;
  border-bottom-right-radius: 200%;


  
}
.extend{
  height: 100vh;
  background-color: maroon;
  border-bottom-right-radius: 2000%;
}
.bottomintro{
  height: 50vh;
  background-color: maroon;
  border-top-left-radius: 200%;
}

.icon{
  font-size: 30px;
}
